/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { utils } from 'web3';
import { useIntl, formatBN } from '@itsa.io/web3utils';
import { Button } from '@itsa.io/ui';
import useStyles from 'styles/components/properties/property';

const address = '425 Piney Way SW';
const city = 'Atlanta';
const state = 'GA';
const zip = 30311;
const price = 200000;
const beds = 4;
const baths = 4;
const mainImage = '/images/buy_a_home.webp';
const type = 'New construction';
const sqft = 1249;

const PropertyDetailed = ({ uri, sellPage }) => {
	// console.debug('uri', uri);
	const { t } = useIntl();
	const classes = useStyles();
	const uriParts = uri.split('&');
	const uriParts0 = uriParts[0] && uriParts[0].split('=');
	const uriParts1 = uriParts[1] && uriParts[1].split('=');
	let propertyId;
	if (uriParts0 && uriParts0[0] === 'id') {
		propertyId = uriParts0[1];
	} else if (uriParts1 && uriParts1[0] === 'id') {
		propertyId = uriParts1[1];
	}
	if (propertyId) {
		propertyId = decodeURIComponent(propertyId);
	}
	// console.debug('propertyId', propertyId);
	let image;
	if (mainImage) {
		image = <img alt="s" className={classes.image} src={mainImage} />;
	}

	let description = '';
	if (typeof beds === 'number') {
		description += ` | <span className={value}>${beds}</span> bd`;
	}
	if (typeof baths === 'number') {
		description += ` | <span className={value}>${baths}</span> ba`;
	}
	if (typeof sqft === 'number') {
		description += ` | <span className={value}>${sqft}</span> sqft`;
	}
	if (type) {
		description += ` - <span className={value}>${type}</span>`;
	}
	if (description) {
		description = description.substring(3);
		// eslint-disable-next-line react/no-danger
		description = <p dangerouslySetInnerHTML={{ __html: description }} />;
	}

	const location = (
		<p>
			{address}, {city}, {state} {zip}
		</p>
	);

	let askPrice;
	if (price) {
		askPrice = `$${formatBN(utils.toBN(price), {
			assetDigits: 0, // toFiat generates 18 assetDigits
			minDigits: 3,
			decimals: 0,
		})}`;
	}

	let editButton;
	if (sellPage) {
		editButton = (
			<Button className={classes.editButton} onClick={() => {}}>
				{t('properties.manage')}
			</Button>
		);
	}

	const propertyInfo = (
		<div className={classes.propertyInfo}>
			<div className={classes.propertyDesc}>
				{description}
				{location}
				{editButton}
			</div>
			<div className={classes.price}>{askPrice}</div>
		</div>
	);

	return (
		<div className={classes.root}>
			{image}
			{propertyInfo}
		</div>
	);
};

PropertyDetailed.propTypes = {
	sellPage: PropTypes.bool.isRequired,
	uri: PropTypes.string.isRequired,
};

export default PropertyDetailed;
