import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
		gap: 20,

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 10,
			fontSize: '80%',
		},
	},

	image: {
		width: 325,
		height: 'fit-content',

		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	propertyInfo: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
		gap: 20,
		fontSize: '130%',

		'& p': {
			marginBottom: 2,
			marginTop: 2,
		},

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
			gap: 10,
			alignSelf: 'flex-start',
		},

		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},

	propertyDesc: {
		// width: 400,
	},

	price: {
		fontSize: '150%',
		fontWeight: 'bold',
		textAlign: 'right',
		flexGrow: 1,
		paddingRight: '1rem',

		[theme.breakpoints.down('md')]: {
			flexGrow: 0,
			textAlign: 'left',
		},

		[theme.breakpoints.down('sm')]: {
			textAlign: 'right',
			flexGrow: 1,
		},
	},

	editButton: {
		fontSize: 16,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.orange[900],
		'&:hover': {
			backgroundColor: theme.palette.orange[500],
			color: theme.palette.text.primary,
		},
		'&:visited': {
			color: theme.palette.text.primary,
		},
	},
}));
