import React from 'react';
import PropTypes from 'prop-types';
import PropertyDetailed from 'components/properties/PropertyDetailed';
import useStyles from 'styles/pages/MyPropertiesDetails';

const MyPropertiesDetailsPage = ({ location }) => {
	const uri = location.search;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<PropertyDetailed sellPage uri={uri.substring(1)} />
		</div>
	);
};

MyPropertiesDetailsPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default MyPropertiesDetailsPage;
